<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card class="pa-10">
      <v-row class="d-flex flex-column">
        <div class="text-center">
          {{ $t("send_an_offer") }}
        </div>
        <div class="text-center mt-10">
          <v-btn>
            <input
              class="input-file"
              id="my-file"
              type="file"
              @change="uploadFile"
              ref="file"
            />
            <span class="mr-2">{{ $t("import_file") }}</span>
            <v-icon color="primary">mdi-cloud-upload</v-icon>
          </v-btn>

          <p class="file-return">{{ fileName }}</p>
        </div>
        <div class="mt-10">
          <tiptap-vuetify
            v-model="message"
            :extensions="extensions"
            placeholder
            class="mb-2"
            style="max-width: 100%"
          />
          <!-- <v-textarea
            v-model="message"
            :placeholder="vModelPlaceholder"
            outlined
          ></v-textarea> -->
        </div>
        <div class="text-right">
          <v-btn text small color="primary" @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn small color="primary" @click="reject()">
            {{ $t("send") }}
          </v-btn>
        </div>
      </v-row>
    </v-card>

    <progress-dialog :processing="isLoading" />
  </v-dialog>
</template>

<script>
document.querySelector("html").classList.add("js");
import { mapGetters } from "vuex";
import { SEND_PROPOSAL_INTERVIEW } from "../graphql/Mutation.gql";
import ProgressDialog from "../../../components/ProgressDialog.vue";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  name: "SendProposalDialog",

  components: {
    TiptapVuetify,
    ProgressDialog,
  },

  data: () => ({
    dialog: false,
    status: "rejected",
    message: "",
    interviewId: null,
    candidate: null,
    isLoading: false,
    file: null,
    fileName: "",
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("SendProposalDialog", ({ interviewId, selectedCandidate }) => {
      this.interviewId = interviewId;
      this.candidate = selectedCandidate;
      this.dialog = true;
      this.message = this.$t("send_proposal_email", {
        entity: this.currentUser.entity.name,
      });
    });
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    candidateName() {
      return this.candidate.interviewee
        ? this.candidate.interviewee.fullName
        : this.candidate.email;
    },
    candidateIdOrEmail() {
      return this.candidate.interviewee
        ? this.candidate.interviewee.id
        : this.candidate.email;
    },
  },

  methods: {
    uploadFile() {
      this.file = this.$refs.file.files[0];
      this.fileName = this.$refs.file.files[0].name;
    },

    async reject() {
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: SEND_PROPOSAL_INTERVIEW,
          variables: {
            interviewSendProposalInput: {
              interviewId: this.interviewId,
              candidateOrEmail: this.candidateIdOrEmail,
              message: this.message,
              file: this.file,
            },
          },
        });
        this.dialog = false;
        const successMsg = this.$t("candidate_was_notified_by_email");
        // eslint-disable-next-line no-undef
        Fire.$emit("SuccessDialog", successMsg);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.input-file-container {
  position: relative;
  width: 225px;
}
.js .input-file-trigger {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  transition: all 0.4s;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 500;

  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.js .input-file {
  position: absolute;
  top: 0;
  left: 0;
  width: 225px;
  opacity: 0;
  padding: 14px 0;
  cursor: pointer;
}
.js .input-file:hover + .input-file-trigger,
.js .input-file:focus + .input-file-trigger,
.js .input-file-trigger:hover,
.js .input-file-trigger:focus {
  background: #f0f0f0;
  color: rgba(0, 0, 0, 0.87);
}

.file-return {
  margin: 0;
}
.file-return:not(:empty) {
  margin: 1em 0;
}
.js .file-return {
  font-style: italic;
  font-size: 0.9em;
  font-weight: bold;
}
.js .file-return:not(:empty):before {
  /* content: "Selected file: "; */
  font-style: normal;
  font-weight: normal;
}
</style>