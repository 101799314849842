<template>
  <v-card class="pt-3 pb-5 px-3">
    <div>
      <div class="d-flex flex-column">
        <div class="d-flex justify-space-between mb-2">
          <div>
            <v-avatar left>
              <img
                :src="
                  candidate.interviewee && candidate.interviewee.photo
                    ? `${apiUrl}/images/candidate/${candidate.interviewee.photo}`
                    : `/avatar.png`
                "
              />
            </v-avatar>
          </div>
          <div>
            <div
              class="d-flex justify-end align-center mt-2"
              style="cursor: pointer"
              @click="openNotes()"
            >
              <v-badge
                :content="candidate.notes.length"
                :value="candidate.notes.length"
                bordered
                color="red"
                left
                overlap
              >
                <div class="d-flex align-center">
                  <div><v-icon color="primary">mdi-folder-outline</v-icon></div>
                </div>
              </v-badge>
              <div class="pl-1 primary--text caption">
                {{ $t("Notepad") }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="caption">{{ fullname }}</div>
          <div class="">{{ profession }}</div>
          <div v-if="professionalExperience" class="caption">
            {{ days(professionalExperience) }} {{ $t("years_of_experience") }}
          </div>
          <div v-else class="caption">Sem experiência</div>
        </div>
        <div class="mt-2" style="width: 55%">
          <v-progress-linear
            :value="rating"
            readonly
            height="18"
            :color="thumbColor"
          >
            <strong style="font-size: 14px">{{ rating.toFixed(2) }}%</strong>
          </v-progress-linear>
        </div>
      </div>
    </div>

    <div
      v-if="candidate.interviewee"
      class="d-flex flex-row justify-space-between align-center mt-10 mb-5"
    >
      <v-btn
        text
        small
        class="px-0"
        color="primary"
        target="_blank"
        :to="`/pdf/${candidate.interviewee.id}`"
      >
        <v-icon small>mdi-file-account-outline</v-icon>
        <span>{{ $t("view_cv") }}</span>
      </v-btn>
    </div>

    <div class="d-flex flex-column mb-10">
      <div>
        <div class="caption">{{ $t("recruiters") }}</div>
        <div>
          <avatar-group :items="avatarGroups(recruters)" />
        </div>
      </div>
      <div>
        <div class="caption mt-4">{{ $t("observers") }}</div>
        <div>
          <avatar-group :items="avatarGroups(observers)" />
        </div>
      </div>
    </div>

    <div>
      <v-btn block small color="primary" :loading="isLoading" @click="go()">
        {{ $t("view_history") }}
      </v-btn>
    </div>

    <notes
      :dialog="noteDialog"
      :interviewId="interviewId"
      :selectedCandidate="candidate"
      @close="noteDialog = false"
    />
  </v-card>
</template>

<script>
import { API_URL } from "@/api";
import Notes from "../panel/Notes.vue";
import yearsMixins from "@/mixins/years";
import AvatarGroup from "../recruter/AvatarGroup.vue";
export default {
  name: "InterviewApprovedCard",

  components: {
    Notes,
    AvatarGroup,
  },

  props: {
    candidate: { type: Object },
    interviewId: { type: String },
  },

  mixins: [yearsMixins],

  data: () => ({
    apiUrl: API_URL,
    noteDialog: false,
    isLoading: false,
  }),

  computed: {
    fullname() {
      return this.candidate.interviewee
        ? this.candidate.interviewee.fullName
        : this.candidate.email;
    },

    profession() {
      return this.candidate.interviewee
        ? this.candidate.interviewee.profession
        : "";
    },

    professionalExperience() {
      return this.candidate.interviewee
        ? this.candidate.interviewee.professionalExperience
        : null;
    },

    candidateIdOrEmail() {
      return this.candidate.interviewee
        ? this.candidate.interviewee.id
        : this.candidate.email;
    },

    recruters() {
      let resultItems = [];
      this.candidate.stages.forEach((eachStage) => {
        resultItems = [...resultItems, ...eachStage.recruters];
      });

      resultItems = resultItems.filter((findRecruter, index) => {
        const recruterIds = resultItems.map(
          (mapRecruter) => mapRecruter.user.id
        );
        return recruterIds.indexOf(findRecruter.user.id) === index;
      });

      return resultItems;
    },

    observers() {
      let resultItems = [];
      this.candidate.stages.forEach((eachStage) => {
        resultItems = [...resultItems, ...eachStage.observers];
      });

      resultItems = resultItems.filter((findObserver, index) => {
        const observerIds = resultItems.map(
          (mapObserver) => mapObserver.user.id
        );
        return observerIds.indexOf(findObserver.user.id) === index;
      });

      return resultItems;
    },

    rating() {
      const INIT_CLASSIFICATION_SUM = 0;
      const FIRST_ELEMENT = 0;
      let resultRecruters = 0;
      let resultObservers = 0;
      let totalPerStage = 0;

      this.candidate.stages.forEach((eachStage) => {
        const stage = eachStage;

        if (
          stage.recruters &&
          stage.recruters.length &&
          stage.recruters[FIRST_ELEMENT].classification &&
          stage.recruters[FIRST_ELEMENT].classification.length > 0
        ) {
          const TOTAL_CLASSIFICATION_IN_STAGE =
            stage.recruters[FIRST_ELEMENT].classification.length;
          const TOTAL_RECRUTERS = stage.recruters.length;
          const TOTAL_OBSERVERS = stage.observers.length || 0;

          stage.recruters.forEach((findRecruter) => {
            resultRecruters += findRecruter.classification.reduce(
              (accumulate, findClassificatiom) => {
                const points = findClassificatiom.points || 0;
                const percentageWeight =
                  findClassificatiom.percentageWeight || 0;
                return accumulate + points * (percentageWeight / 100);
              },
              INIT_CLASSIFICATION_SUM
            );
          });

          if (stage.observers.length > 0) {
            stage.observers.forEach((findObserver) => {
              resultObservers += findObserver.classification.reduce(
                (accumulate, findClassificatiom) => {
                  const { points, percentageWeight } = findClassificatiom;
                  return accumulate + points * (percentageWeight / 100);
                },
                INIT_CLASSIFICATION_SUM
              );
            });
          }
          totalPerStage +=
            (resultRecruters + resultObservers) /
              TOTAL_CLASSIFICATION_IN_STAGE /
              (TOTAL_RECRUTERS + TOTAL_OBSERVERS) || 0;
        }
      });

      return totalPerStage;
    },

    thumbColor() {
      if (this.rating < 40) return "red";
      if (this.rating < 50) return "orange";
      if (this.rating < 75) return "indigo";
      if (this.rating < 100) return "teal";
      return "green";
    },
  },

  methods: {
    openNotes() {
      this.noteDialog = true;
    },

    go() {
      this.isLoading = true;
      this.$router.push({
        name: "interviews.approved",
        params: {
          id: this.$route.params.id,
        },
        query: {
          candidateIdOrEmail: this.candidateIdOrEmail,
        },
      });
    },

    recruterName(name) {
      return name.split(" ")[0];
    },

    avatarGroups(recruters) {
      return recruters.map((mapRecruter) => ({
        id: mapRecruter.user.id,
        name: mapRecruter.user.name,
        photo: mapRecruter.user.photo,
      }));
    },
  },
};
</script>